<template>
  <div class="error-content">
    <img
      src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/account/error.png"
    />
    <p>{{ errorText }}</p>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "404",
  components: {},

  setup() {
    const route = useRoute();

    const errorText = route.query.message || ref("抱歉，当前页面无法打开");
    return { errorText };
  }
};
</script>

<style lang="less" scoped>
.error-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background: #fff;
  img {
    width: 200px;
    height: 160px;
  }
  p {
    margin-top: 40px;
    color: #4d4d4d;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
